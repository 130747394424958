/* eslint-disable no-lone-blocks */
import React, {useState} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import {
    ButtonPrimary,
    Logo,
    BurgerMenu,
    ShadowChatList,
    LanguageInfo,
} from '@components'
import styles from './style.module.scss'
import {ReactComponent as UserIcon} from '../../components/Header/assets/EmptyUser.svg'
import {ReactComponent as PlusIcon} from '../../pages/GenerationBlock/pages/ShadowGPT/assets/Plus.svg'

import avatar from '../../pages/GenerationBlock/components/SideMenu/assets/Avatar.png'
import arrow from '../../pages/GenerationBlock/components/SideMenu/assets/arrow.svg'
import burgerBtn from './assets/Vector.svg'
import {useUnit} from 'effector-react'
import {$user} from '../../features/app/model'
import {$dropDownShow, dropDownHandler} from '../../features/header/model'
import HeaderDropdown from './components/dropdown'
import {
    $chatList,
    activeChatEvent,
    chatListHandler,
    createChatFx,
} from '../../features/chat/model'
import useOnclickOutside from 'react-cool-onclickoutside'
import {useTranslation} from 'react-i18next'

import {ReactComponent as StarsIcon} from '../../pages/GenerationBlock/components/SideMenu/assets/Stars.svg'
import {ReactComponent as StickIcon} from '../../pages/GenerationBlock/components/SideMenu/assets/Stick.svg'
import {ReactComponent as CrownIcon} from '../../pages/GenerationBlock/components/SideMenu/assets/Crown.svg'
import {ReactComponent as MoneyIcon} from '../../pages/GenerationBlock/components/SideMenu/assets/Money.svg'
import {ReactComponent as TimeCycleIcon} from '../../pages/GenerationBlock/components/SideMenu/assets/TimeCycle.svg'
import {ReactComponent as OutIcon} from '../../pages/GenerationBlock/components/SideMenu/assets/Out.svg'
import {ReactComponent as EmptyUserIcon} from './assets/EmptyUser.svg'
import {ReactComponent as MessagesIcon} from '../../pages/GenerationBlock/components/SideMenu/assets/Messages.svg'

export const Header: React.FC = () => {
    const user = useUnit($user)
    const [dropdownShow, chatList] = useUnit([$dropDownShow, $chatList])
    const navigate = useNavigate()
    const {pathname: activePage} = useLocation()
    const [isShowLang, setIsShowLang] = useState<boolean>(false)

    const [mobileMenuOpened, setMobileMenuOpened] = useState(false)
    const [shadowChatMenuOpened, setShadowChatMenuOpened] = useState(false)
    const [accountMenuOpened, setAccountMenuOpened] = useState(false)

    const {t, ready} = useTranslation()

    const HeaderData = t('header.pc', {returnObjects: true})
    const HeaderMobile = t('header.mobile', {returnObjects: true})
    const HeaderMobileData = t('header.mobileData', {returnObjects: true})

    const createCreate = () => {
        createChatFx(`${t('chat.chatName')} #${chatList.length + 1}`).then(
            (res: any) => {
                let pinedChats = chatList.filter((chat: any) => chat.isPined)
                let notPinedChats = chatList.filter(
                    (chat: any) => !chat.isPined
                )

                activeChatEvent(res)

                chatListHandler([...pinedChats, res, ...notPinedChats])
            }
        )
    }

    const signInButtonClickHandler = () => {
        navigate('/auth')
    }

    const toggleShadowChatMenuClickHandler = () =>
        setShadowChatMenuOpened(!shadowChatMenuOpened)
    const toggleMenuClickHandler = () => setMobileMenuOpened(!mobileMenuOpened)
    const toggleaccountMenuClickHandler = () =>
        setAccountMenuOpened(!accountMenuOpened)

    const renderMobileHeaderContent = () => {
        if (activePage === '/generation/shadow-chat') {
            return (
                <div className={styles.HeaderMobile__ShadowChatButtons}>
                    <ButtonPrimary
                        onClick={toggleShadowChatMenuClickHandler}
                        className={styles.HeaderMobile__button}
                    >
                        <small className={styles.HeaderMobile_grayColor}>
                            {t('header.allChats')}
                        </small>
                        <MessagesIcon
                            width={16}
                            height={16}
                            fill="rgba(168, 170, 183, 1)"
                        />
                    </ButtonPrimary>
                    <ButtonPrimary
                        colorScheme="orange"
                        className={styles.HeaderMobile__button}
                        onClick={createCreate}
                    >
                        <small>{t('chat.newChat')}</small>
                        <PlusIcon/>
                    </ButtonPrimary>
                </div>
            )
        }

        return <Logo/>
    }

    const ref = useOnclickOutside(() => {
        dropDownHandler(false)
    })

    return (
        <div className={styles.PositionWrapper}>
            <header className={`app-container`}>
                <div className={`${styles.Header}`}>
                    <Logo/>
                    <div className={styles.Header__menu}>
                        {/* @ts-ignore */}
                        {HeaderData.length && ready
                            ? //@ts-ignore
                            HeaderData.map((el: any) => (
                                <div
                                    onClick={() => navigate(el.path)}
                                    className={`${styles.Header__menuItem} ${
                                        activePage === el.path
                                            ? styles.Header__menuItem_active
                                            : ''
                                    }`}
                                >
                                    {el.title}
                                </div>
                            ))
                            : null}
                    </div>
                    {' '}
                    <div
                        className={
                            localStorage.getItem('token')
                                ? styles.Header__right
                                : `${styles.Header__right} ${styles.Header__gap}`
                        }
                    >
                        {localStorage.getItem('token') !== null && (
                            <>
                                <LanguageInfo
                                    set={setIsShowLang}
                                    val={isShowLang}
                                    position={{top: '30px'}}
                                />{' '}
                                <div
                                    ref={ref}
                                    onClick={() =>
                                        dropDownHandler(!dropdownShow)
                                    }
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {!user.image ? (
                                        <UserIcon
                                            fill="white"
                                            width={32}
                                            height={32}
                                            className={
                                                styles.AccountMenu__avatar
                                            }
                                        />
                                    ) : (
                                        <img
                                            src={`data:image/jpeg;base64,${user.image}`}
                                            alt="ava"
                                            className={
                                                styles.AccountMenu__avatar
                                            }
                                        />
                                    )}
                                    <p>
                                        {user.firstName ? user.firstName : null}
                                        {!user.firstName ? user.email : null}
                                        {!user.email ? user.phone : null}
                                    </p>
                                    <img
                                        src={arrow}
                                        alt="arrow"
                                        style={
                                            dropdownShow
                                                ? {
                                                    transform:
                                                        'rotate(180deg)',
                                                    transition:
                                                        '.2s all ease',
                                                }
                                                : {
                                                    transform: 'rotate(0)',
                                                    transition:
                                                        '.2s all ease',
                                                }
                                        }
                                    />{' '}
                                    {Object.keys(user).length && (
                                        <HeaderDropdown/>
                                    )}
                                </div>
                            </>
                        )}
                        {localStorage.getItem('token') === null && (
                            <>
                                <LanguageInfo
                                    set={setIsShowLang}
                                    val={isShowLang}
                                    position={{top: '30px'}}
                                />
                                <ButtonPrimary
                                    onClick={signInButtonClickHandler}
                                >
                                    {t('auth.signIn')}
                                </ButtonPrimary>
                                <ButtonPrimary colorScheme="orange" onClick={signInButtonClickHandler}>
                                    {t('auth.getStarted')}
                                </ButtonPrimary>
                            </>
                        )}
                    </div>
                </div>
                <div className={`${styles.HeaderMobile}`}>
                    <img
                        onClick={toggleMenuClickHandler}
                        width={18}
                        height={14}
                        src={burgerBtn}
                        alt=""
                    />
                    {renderMobileHeaderContent()}
                    <div
                        onClick={toggleaccountMenuClickHandler}
                        className={styles.HeaderMobile__avatar}
                    >
                        {!user.image ? (
                            // <img
                            //     src={avatar}
                            //     alt="ava"
                            //     className={styles.HeaderMobile__avatar}
                            // />
                            <EmptyUserIcon fill="#fff"/>
                        ) : (
                            <img
                                src={`data:image/jpeg;base64,${user.image}`}
                                alt="ava"
                                className={styles.HeaderMobile__avatar}
                            />
                        )}
                    </div>
                </div>
            </header>

            <BurgerMenu
                closeClickHandler={toggleMenuClickHandler}
                menuOpened={mobileMenuOpened}
            >
                <div className={styles.HeaderMobile__menuLogo}>
                    <Logo/>
                </div>

                <div className={styles.HeaderMobile__menuContainer}>
                    {/* @ts-ignore */}
                    {HeaderMobile.length && ready
                        ? //@ts-ignore
                        HeaderMobile.map((it: any, index: number) => {
                            const clickHandler = () => {
                                navigate(it.path)
                                toggleaccountMenuClickHandler()
                            }
                            const isActivePage = it.path === activePage
                            const titleStyles = isActivePage
                                ? styles.HeaderMobile__menuContainer_active
                                : styles.HeaderMobile__menuContainer_inactive

                            const fillColor = isActivePage
                                ? 'rgba(253, 55, 7, 1)'
                                : '#F8F8F8'

                            return (
                                <div onClick={clickHandler}>
                                    <div
                                        className={
                                            styles.HeaderMobile__menuContainer_icon
                                        }
                                    >
                                        {index === 0 && (
                                            <StickIcon fill={fillColor}/>
                                        )}
                                        {index === 1 && (
                                            <StarsIcon fill={fillColor}/>
                                        )}
                                        {index === 2 && (
                                            <CrownIcon fill={fillColor}/>
                                        )}
                                    </div>
                                    <p className={titleStyles}>{it.title}</p>
                                </div>
                            )
                        })
                        : null}
                </div>
            </BurgerMenu>
            <BurgerMenu
                closeClickHandler={toggleShadowChatMenuClickHandler}
                menuOpened={shadowChatMenuOpened}
            >
                <h3 className={styles.ShadowChatMenu__title}>ShadowChat</h3>
                <div className={styles.ShadowChatMenu__wrapper}>
                    <ShadowChatList/>
                </div>
            </BurgerMenu>

            <BurgerMenu
                isRight={true}
                closeClickHandler={toggleaccountMenuClickHandler}
                menuOpened={accountMenuOpened}
            >
                <div className={styles.AccountMenu}>
                    <div className={styles.AccountMenu__flexWrapper}>
                        {!user.image ? (
                            <UserIcon
                                style={{margin: "0"}}
                                fill="white"
                                width={32}
                                height={32}
                                className={styles.AccountMenu__avatar}
                            />
                        ) : (
                            <img
                                src={`data:image/jpeg;base64,${user.image}`}
                                alt="ava"
                                className={styles.AccountMenu__avatar}
                            />
                        )}
                        <p className={styles.AccountMenu__avatarTitle}>
                            {user.firstName ? user.firstName : null}
                            {!user.firstName ? user.email : null}
                            {!user.email ? user.phone : null}
                        </p>
                    </div>
                    <div className={styles.HeaderMobile__menuContainer}>
                        {/* @ts-ignore */}
                        {HeaderMobileData.length && ready
                            ? //@ts-ignore
                            HeaderMobileData.map((it: any, index: number) => {
                                const clickHandler = () => {
                                    navigate(it.path)
                                    toggleaccountMenuClickHandler()
                                }
                                const isActivePage = it.path === activePage
                                const titleStyles = isActivePage
                                    ? styles.HeaderMobile__menuContainer_active
                                    : styles.HeaderMobile__menuContainer_inactive

                                const fillColor = isActivePage
                                    ? 'rgba(253, 55, 7, 1)'
                                    : '#F8F8F8'

                                return (
                                    <div onClick={clickHandler}>
                                        <div
                                            className={
                                                styles.HeaderMobile__menuContainer_icon
                                            }
                                        >
                                            {index === 0 && (
                                                <EmptyUserIcon
                                                    fill={"#fff"}
                                                />
                                            )}

                                            {index === 1 && (
                                                <MoneyIcon fill={fillColor}/>
                                            )}
                                            {index === 2 && (
                                                <TimeCycleIcon
                                                    fill={fillColor}
                                                />
                                            )}
                                            {index === 3 && (
                                                <MessagesIcon
                                                    fill={fillColor}
                                                />
                                            )}
                                            {index === 4 && (
                                                <OutIcon fill={fillColor}/>
                                            )}
                                        </div>
                                        <p className={titleStyles}>
                                            {it.title}
                                        </p>
                                    </div>
                                )
                            })
                            : null}
                    </div>
                    {/* <LanguageInfo /> */}
                </div>
            </BurgerMenu>
        </div>
    )
}
