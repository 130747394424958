import React from 'react'
import style from './style.module.scss'

import CopyLeaks from './assets/CopyLeaks.svg'
import GPTZero from './assets/GPTZero.svg'
import ZeroGPT from './assets/ZeroGPT.svg'
import Sapling from './assets/Sapling.svg'
import { ReactComponent as CheckIcon } from './assets/CheckIcon.svg'
import { ReactComponent as AiDetectedIcon } from './assets/AiDetected.svg'
import { ReactComponent as UncertainIcon } from './assets/Uncertain.svg'
import { ReactComponent as LoaderIcon } from './assets/Loader.svg'
import { useTranslation } from 'react-i18next'

type Props = {
    loading?: boolean
    bottomAiResultBadge?: boolean
    data?: any
}

const imgs = [
    {
        name: 'COPYLEAKS',
        url: CopyLeaks,
    },
    {
        name: 'SAPLING',
        url: Sapling,
    },
    {
        name: 'GPT_ZERO',
        url: GPTZero,
    },
    {
        name: 'ZERO_GPT',
        url: ZeroGPT,
    },
]

const getBarColor = (percent: number) => {
    let color = 'rgba(242, 26, 52, 1)'

    if (percent < 60) {
        color = 'rgba(253, 137, 7, 1)'
    }

    if (percent < 30) {
        color = 'rgba(0, 172, 59, 1)'
    }

    return color
}

export const TextDetectionResult: React.FC<Props> = ({
    loading = false,
    bottomAiResultBadge = true,
    data = [],
}) => {
    const renderItems = () => {
        return (
            <div className={style.TextDetectionResult}>
                <div className={style.TextDetectionResult__flexWrapper}>
                    {data.length &&
                        data.map((el: any, index: number) => (
                            <div
                                className={
                                    style.TextDetectionResult__indicatorContainer
                                }
                            >
                                <div
                                    className={
                                        style.TextDetectionResult__titleContainer
                                    }
                                >
                                    <img
                                        className={
                                            style.TextDetectionResult__titleContainer_img
                                        }
                                        src={imgs[index].url}
                                        alt=""
                                    />
                                    {loading ? (
                                        <LoaderIcon
                                            className={style.LoaderAnimation}
                                        />
                                    ) : (
                                        <small>
                                            {!el.isError &&
                                                `${Math.round(el.text * 100)}%`}
                                            {el.isError && t('detection.error')}
                                        </small>
                                    )}
                                </div>
                                <div
                                    className={
                                        style.TextDetectionResult__indicator
                                    }
                                >
                                    <div
                                        style={{
                                            width: `${loading
                                                ? 0
                                                : !el.isError &&
                                                Math.round(el.text * 100)
                                                }%`,
                                            backgroundColor: getBarColor(
                                                el.text * 100
                                            ),
                                        }}
                                        className={
                                            style.TextDetectionResult__indicator_greenBar
                                        }
                                    />
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        )
    }

    const { t } = useTranslation()

    const renderResultIcon = () => {
        if (data && !loading) {
            const resultSum = (data
                .map((result: any) => parseFloat(result.text)) as number[])
                .reduce((sum, cur) => sum += cur, 0);

            const resultAverage = Math.floor(resultSum / data.length * 100);

            if (resultAverage > 75) {
                return (
                    <div
                        style={{
                            position: 'absolute',
                            top: '-44px',
                            left: '0px',
                        }}
                        className={style.ResultIconContainer}
                    >
                        <div className={style.ResultIconContainer__indicator}>
                            <AiDetectedIcon />
                        </div>
                        <small>{t('detection.detected')}</small>
                    </div>
                );
            }

            if (resultAverage == 50) {
                return (
                    <div
                        style={{
                            position: 'absolute',
                            top: '-44px',
                            left: '0px',
                        }}
                        className={style.ResultIconContainer}
                    >
                        <div className={style.ResultIconContainer__indicator}>
                            <UncertainIcon />
                        </div>
                        <small>{t('detection.uncertain')}</small>
                    </div>
                )
            }

            if (resultAverage <= 75 && resultAverage > 40) {
                return (
                    <div
                        style={{
                            position: 'absolute',
                            top: '-44px',
                            left: '0px',
                        }}
                        className={style.ResultIconContainer}
                    >
                        <div className={style.ResultIconContainer__indicator}>
                            <UncertainIcon />
                        </div>
                        <small>{t('detection.uncertain')}</small>
                    </div>
                );
            }

            if (resultAverage <= 40) {
                return (
                    <div
                        style={{
                            position: 'absolute',
                            top: '-54px',
                            left: '0px',
                        }}
                        className={style.ResultIconContainer}
                    >
                        <div className={style.ResultIconContainer__indicator_green}>
                            <CheckIcon />
                        </div>
                        <small>{t('detection.notDetected')}</small>
                    </div>
                );
            }
        }

        return <></>
    }

    return (
        <div className={style.Container}>
            {data && !loading ? (
                renderItems()
            ) : (
                <div className={style.TextDetectionResult}>
                    <div className={style.TextDetectionResult__flexWrapper}>
                        <div
                            className={
                                style.TextDetectionResult__indicatorContainer
                            }
                        >
                            <div
                                className={
                                    style.TextDetectionResult__titleContainer
                                }
                            >
                                {loading ? (
                                    <LoaderIcon
                                        className={style.LoaderAnimation}
                                    />
                                ) : (
                                    <small></small>
                                )}
                            </div>
                            <div
                                className={style.TextDetectionResult__indicator}
                            >
                                <div
                                    style={{ width: `${loading ? 0 : 0}%` }}
                                    className={
                                        style.TextDetectionResult__indicator_greenBar
                                    }
                                />
                            </div>
                        </div>
                        <div
                            className={
                                style.TextDetectionResult__indicatorContainer
                            }
                        >
                            <div
                                className={
                                    style.TextDetectionResult__titleContainer
                                }
                            >
                                {/* <ZeroGPT
                                    className={
                                        style.TextDetectionResult__titleContainer_img
                                    }
                                /> */}
                                {loading ? (
                                    <LoaderIcon
                                        className={style.LoaderAnimation}
                                    />
                                ) : (
                                    <small>{0}%</small>
                                )}
                            </div>
                            <div
                                className={style.TextDetectionResult__indicator}
                            >
                                <div
                                    style={{ width: `${loading ? 0 : 0}%` }}
                                    className={
                                        style.TextDetectionResult__indicator_greenBar
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className={style.TextDetectionResult__flexWrapper}>
                        <div
                            className={
                                style.TextDetectionResult__indicatorContainer
                            }
                        >
                            <div
                                className={
                                    style.TextDetectionResult__titleContainer
                                }
                            >
                                {/* <CopyLeaks
                                    className={
                                        style.TextDetectionResult__titleContainer_img
                                    }
                                /> */}
                                {loading ? (
                                    <LoaderIcon
                                        className={style.LoaderAnimation}
                                    />
                                ) : (
                                    <small></small>
                                )}
                            </div>
                            <div
                                className={style.TextDetectionResult__indicator}
                            >
                                <div
                                    style={{ width: `${loading ? 0 : 0}%` }}
                                    className={
                                        style.TextDetectionResult__indicator_greenBar
                                    }
                                />
                            </div>
                        </div>
                        <div
                            className={
                                style.TextDetectionResult__indicatorContainer
                            }
                        >
                            <div
                                className={
                                    style.TextDetectionResult__titleContainer
                                }
                            >
                                {/* <Sapling
                                    fill="#fff"
                                    className={
                                        style.TextDetectionResult__titleContainer_img
                                    }
                                /> */}
                                {loading ? (
                                    <LoaderIcon
                                        className={style.LoaderAnimation}
                                    />
                                ) : (
                                    <small></small>
                                )}
                            </div>
                            <div
                                className={style.TextDetectionResult__indicator}
                            >
                                <div
                                    style={{ width: `${loading ? 0 : 0}%` }}
                                    className={
                                        style.TextDetectionResult__indicator_greenBar
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {bottomAiResultBadge && (
                <div className={style.ResultIconContainer}>
                    <div className={style.ResultIconContainer__indicator}>
                        <CheckIcon />
                    </div>
                    <small>{t('detection.notDetected')}</small>
                </div>
            )}
            {!bottomAiResultBadge && !loading && renderResultIcon()}
        </div>
    )
}
